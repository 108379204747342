<template>

  <div>
    <div>
      <span>detail</span>
    </div>
  </div>

</template>

<script>
export default {
  name: "detail"
}
</script>

<style scoped>

</style>